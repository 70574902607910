/* This file contains (at least it is supposed to) the formatting of
   the menus itself.  The positioning of menus in the theme is
   declared in base.css */

.menu-divider {
  position: relative;
  top: 0.47em;
  border-style: solid;
  border-width: 0 1px 0 0;
  font-size: 75%;
  color: transparent;
  margin-left: 0.8em;
  margin-right: 1em;
}
.menu-divider:after {
  content: '|';
}
/* Drop-Down-Menue Grundbau */
.menu,
.menu > * {
  display: inline-block;
  line-height: 1.8em;
  list-style-type: none;
  list-style-image: none;
}
.menu ul {
  display: none;
  position: absolute;
  padding-top: 1px;
  width: auto;
  list-style-type: none;
  list-style-image: none;
  white-space: normal;
}
.menu a:hover ul, .menu a:focus ul {
  display: block;
  z-index: 350 !important;
}
.menu li {
  display: inline-block;
  position: relative;
  margin: 0;
}
.menu li a {
  display: inline-block;
  white-space: nowrap;
  padding-left: 0.5em;
  padding-right: 0.5em;
  line-height: 1.8em;
}

/* erste Ebene - horizontal */
.menu > li > ul {
}
/* 2.Ebene - klappt nach unten */
.menu li li {
  background-color: #036;
  min-width: 100%;
  margin-bottom: 1px;
}
.menu li li a {
  padding: 0 0.5em;
  text-align: left;
}
/* 3. Ebene - klappt nach rechts */
.menu ul ul {
  top: -1px;
  left: 100%;
}

/* header-menu
   hellblaue Querleiste */

.header-menu {
  white-space: nowrap;
  height: 1.45rem;
  z-index: 90;
}

.header-menu #search-menu {
  float: right;
  position: relative;
  right: 1em;
}

#search-menu,
#search-menu ul,
#search-menu li {
  background-color: #ccd6e0;
  display: inline-block;
  height: 1.6em;
}

/* audience-menu
   dunkelblaue Querleiste */

#audience-menu-wrapper,
#above-content-wrapper {
  padding: 0 0 0 0;
}

.audience-menu {
  display: block;
  padding: 0;
  padding-left: 3em;
  /* the links in the audience menu have a 0.3em padding ==> We have to move the first element 0.5em to left. */
  margin-left: -0.5em;
  margin-top: -0.05em;
  
  ul {
	padding: 0;
  }
  li {
    display: inline;
  }
}
.frontpageslider #menu-bars #audience-menu {
  margin-top: 0 !important;
}
#audience-menu {
  min-height: 1.45rem;
}
#audience-menu a {
  padding: 0 0.5em 0 0.75em;
  display: inline-block;
}
#audience-menu ul li ul,
#audience-menu .menu ul > * {
  z-index: 350;
}
#audience-menu .menu li li {
  padding: 0 0.5em;
  margin-left: -0.5em;
}
/* Bearbeitungsmenue */

#congentActionMenus dl.actionMenu a,
#contentActionMenus dl.actionMenu.activated dd {
  z-index: 5;
}
#edit-bar {
  padding-top: 0.5em;
  z-index: 300;
  width: 100.1%;
}
#edit-bar > *,
.contentActions > * {
  margin: 0;
  padding: 0 0.5em;
}

.actionMenuContent ul {
  margin: 0;
  padding: 0.5em;
}
.actionMenuContent li,
.actionMenuContent a {
  margin: 0;
}

#_language_settings {
  display: none !important;
}

#content-views {
  float: left;
}

#contentActionMenus {
  float: right;
  position: relative;
  top: -0.1em;
  left: 0.5em;
}
.actionMenuContent {
  padding: 0 0.25em !important;
  margin: 0 0.75em;
}
dl#plone-contentmenu-factories dd.actionMenuContent a {
  line-height: 2em;
  padding: 0 !important;
  background-color: transparent;
  background-repeat: no-repeat;
}
dl#plone-contentmenu-factories dd.actionMenuContent a span {
  padding: 0 0 0 20px;
  min-height: 16px;
}
dl#plone-contentmenu-factories dd.actionMenuContent a img {
  padding-bottom: 3px;
}
dl#plone-contentmenu-factories dd.actionMenuContent a img + span {
  padding: 0;
}
#portal-personaltools-wrapper .actionMenu {
  float: none;
}
#portal-personaltools {
  font-size: 80%;
  z-index: 3;
}
#portal-personaltools dt.actionMenuHeader,
#portal-personaltools dt#anon-personalbar {
  margin-left: 0 !important;
}
#portal-personaltools dt.actionMenuHeader a:after {
  content: ' ▼';
}
#portal-personaltools dd {
  background: #e5eaef;
}
#portal-personaltools dd a {
  line-height: 2em;
  padding: 0 0.75em;
}
#portal-personaltools dd a:hover {
  background: #036;
  color: White;
}
#portal-personaltools #anon-personalbar a {
  float: left;
  margin-right: 0.5em;
}
/* Workflow-Anzeige */
#plone-contentmenu-workflow .state-private,
#plone-contentmenu-workflow .state-visible,
#plone-contentmenu-workflow .state-pending,
#plone-contentmenu-workflow .state-published {
  padding: 0 0.5em;
  border-width: 0.1em;
  border-style: solid;
}
/* Tag-Cloud */
.portletTagCloudPortlet .portletHeader {
  display: block;
}

/* Language select */
a#_universal_link,
a#_shared_folder {
  visibility: hidden;
  height: 0;
}

/* Audience menu & search menu, new style menu */
/* TODO: Wenn die alten Menues nicht mehr genutzt werden,
 * sollten die Styles soweit erforderlich hierher umgesetzt werden */
.audience-menu.audience-menu-new-style,
.search-menu.search-menu-new-style {
  font-size: 96%;
  margin-left: 0;
  line-height: 1.8em;
  padding-left: 1.3rem;
}

.audience-menu-new-style ul li div,
.search-menu-new-style ul li div {
  display: inline-block;
}

.audience-menu-new-style ul li div.submenu,
.search-menu-new-style ul li div.submenu {
  position: relative;
}

.audience-menu-new-style ul li div.submenu .submenu-children,
.search-menu-new-style ul li div.submenu .submenu-children {
  position: absolute;
  top: 100%;
  margin-top: 0px;
  padding-top: 1px;
  padding-inline-start: 0;
  z-index: 10;
}

.audience-menu-new-style ul li div.submenu .submenu-children > li,
.search-menu-new-style ul li div.submenu .submenu-children > li {
  background-color: #036;
  border-top: 1px solid white;
  line-height: 1.8em;
  margin-bottom: 0;
  display: block !important;
  padding-bottom: 0.3em;
  padding-right: 0.3em;
  white-space: nowrap;
}
.search-menu-new-style ul ul li div.submenu .submenu-children > li {
  margin-left: -5em;
}
.search-menu-new-style ul ul {
  margin-left: -0.75em;
}
.audience-menu-new-style ul li div.submenu .submenu-children > li a,
.search-menu-new-style ul li div.submenu .submenu-children > li a {
  display: block !important;
  font-size: 98%;
  padding: 0 0.75em 0 0.75em;
}
.audience-menu-new-style ul ul li div {
  display: block;
}
.audience-menu-new-style ul ul li div.submenu .submenu-children {
  margin-left: 5em;
  margin-top: 2px;
}
search-menu-new-style ul ul li div.submenu .submenu-children {
  margin-left: -5em;
}

.audience-menu-new-style li .menu-divider,
.search-menu-new-style li .menu-divider {
  margin-left: 0.3em;
  margin-right: 0.5em;
}
