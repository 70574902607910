/* humboldt.theme */
/* Global variables and redefinitions */

$hu-grey: #ccd6e0;
$hu-blue: #003366;
$hu-slider-light-blue: rgba(170, 187, 204, 0.95);
$hu-slider-titlearrows-blue: #4477aa;
$hu-bluish-grey: #8099b2;
$hu-bluish-grey-border: #6685a3;
$hu-dark-bluish-grey: #7f9cbe;
$hu-yellow: #ffa500;
$hu-menu-yellow: #cccc99;
$hu-backdrop-grey: #f0f0e8;
$hu-background-white: #fff;
$hu-footer-grey: #b2c1d1;
$hu-navigation-level-2: #e0e0d1;
$hu-text-grey: #666666;

/* Override less variables used in plone/barcelonetta. */
/* Use e.g. for grid and colors.                       */
/* See ./barcelonetta/less/variables.plone.scss for a  */
/* full list of available variables.                   */

$plone-border-radius-base: 0px;
$plone-border-radius-large: 0px;
$plone-border-radius-small: 0px;
$plone-input-border: $hu-bluish-grey-border;
$plone-portlet-border: none;
$plone-portlet-header-bg: transparent;
$plone-breadcrumb-bg: transparent;
$plone-portlet-header-color: $hu-blue;
$plone-page-header-border-color: transparent;
$plone-font-size-base: 12.8px;
$plone-text-color: #000;

// breakpoints
$plone-container-md: 100%;
$plone-screen-lg-min: 1280px;
$plone-container-lg: 1280px;
