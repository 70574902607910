/* -*- mode: css -*-

Coding style:

This file should contains color and border information ONLY.
*/

/* The following comment section contains variable definitions in
"less" for the main colors used in humboldt.theme

$blue100:        rgb( 0   ,  51 , 102); // #003366
$blue060:        rgb( 102 , 133 , 163); // #6685A3
$blue030:        rgb( 178 , 193 , 209); // #B2C1D1
$blue020:        rgb( 204 , 214 , 224); // #CCD6E0
$blue010:        rgb( 229 , 234 , 239); // #E5EAEF

$link_unvisited: rgb( 0   , 102 , 153); // #006699
$link_hover:     rgb( 102 , 102 ,  51); // #666633
$link_visited:   rgb( 68  , 119 , 170); // #4477AA

$navi_level1:    rgb( 204 , 204 , 153); // #CCCC99
$navi_level2:    rgb( 224 , 224 , 209); // #E0E0D1
$navi_level3:    rgb( 229 , 229 , 217); // #E5E5D9
$navi_level4:    rgb( 255 , 255 , 255); // #FFFFFF

$green100:       rgb( 153 , 153 , 102); // #999966
$green050:       rgb( 204 , 204 , 178); // #CCCCB2
$green030:       rgb( 224 , 224 , 209); // #E0E0D1
$green015:       rgb( 240 , 240 , 232); // #F0F0E8

$gray:           rgb( 102 , 102 , 102); // #666666

$bodyborder:     #CCCC99;
$portalLanguageSelectBackground: #8099B2;
$toc-background: #E8E8E0;
*/

/* base */
body {
  background-color: #f0f0e8;
}
.container {
  background-color: $hu-background-white;
  border-left: 0.5px solid #cccc99;
  border-right: 0.5px solid #cccc99;
}

#visual-portal-wrapper > hr {
  color: #003366;
}
hr {
  color: #b2c1d1;
  background-color: #b2c1d1;
}
#portal-column-content * {
  border-color: #b2c1d1;
}

h1,
h1 a,
h1 a:visited,
h2,
h2 a,
h2 a:visited,
h3,
h3 a,
h3 a:visited,
h4,
h4 a,
h4 a:visited,
h5,
h5 a,
h5 a:visited,
h6,
h6 a,
h6 a:visited,
.title,
.title a,
.title a:visited,
a.title,
a:visited.title {
  color: $hu-blue !important;
}

input {
  border-color: $hu-bluish-grey-border;
}

.error {
  color: red !important;
  border: 1px red solid;
}
/* workflow */

.state-private,
.state-expired {
  color: #933 !important;
}
.state-visible {
  color: #7f9cbe !important;
}
.state-published {
  color: #036 !important;
}
.state-pending {
  color: #ffa500 !important;
}
.syndicated {
  color: #e0e0d1 !important;
}

#plone-contentmenu-workflow .state-private,
#plone-contentmenu-workflow .state-expired {
  color: white !important;
  background-color: #933;
  border-color: #933;
}
#plone-contentmenu-workflow .state-visible {
  color: white !important;
  background-color: #7f9cbe;
  border-color: #7f9cbe;
}
#plone-contentmenu-workflow .state-published {
  color: white !important;
  background-color: #036;
  border-color: #036;
}
#plone-contentmenu-workflow .state-pending {
  color: white !important;
  background-color: #ffa500;
  border-color: #ffa500;
}
#plone-contentmenu-workflow .syndicated {
  color: #036 !important;
  background-color: #e0e0d1;
  border-color: #e0e0d1;
}

/* menus */
.menu-divider {
  background-color: transparent;
  border-color: white;
}
#audience-menu,
#audience-menu a {
  background-color: #003366;
  color: white;
}

.header-menu {
  background-color: #ccd6e0;
  color: #003366;
}
#search-menu,
#search-menu ul,
#search-menu li {
  background-color: #ccd6e0;
}

/* color settings for the language selector portlet */

.portletLanguageSelect {
  background-color: #8099b2;
  color: white;
}

.portletLanguageSelect a,
.portletLanguageSelect a:visited {
  color: white;
}

#search-menu a {
  color: #003366;
}
ul.menu ul {
  background-color: white !important;
}

/* portlets */
.portlet-portal-title,
.portlet-portal-title a,
.portlet-portal-title a:visited,
.portlet-portal-title a:hover,
dt.portletHeader,
dt.portletHeader a,
dt.portletHeader a:visited,
dt.portletHeader a:hover,
dt.portletHeader span {
  color: $hu-blue;
}
.portlet {
  .card-body > ul.navTree {
    &.navTreeLevel0 > .navTreeItem {
      &,
      &:hover {
        background-color: $hu-menu-yellow;
        > a {
          border-bottom-color: white;
        }
      }
    }
    ul.navTreeLevel1 > .navTreeItem {
      &,
      &:hover {
        background-color: $hu-navigation-level-2;
        > a {
          border-bottom-color: white;
        }
      }
    }
    ul.navTreeLevel2 > .navTreeItem {
      &,
      &:hover {
        background-color: $hu-backdrop-grey;
        border-right: 1px solid $hu-backdrop-grey;
        > a {
          border-bottom-color: white;
        }
      }
    }
    ul.navTreeLevel3 .navTreeItem {
      &,
      &:hover {
        background-color: white;
        a {
          border-bottom-color: $hu-backdrop-grey;
        }
      }
    }
  }
}

.portlet .portlet ul.navTreeLevel3 .navTreeItem #header-menu a,
.portlet ul.navTree li a,
.portlet ul.navTree li a:hover,
.portlet ul.navTree li a:visited {
  color: #003366 !important;
}
.ploneCalendar dt,
.ploneCalendar td,
.ploneCalendar .portletHader,
.ploneCalendar .portletHader a {
  color: #003366;
}
.portletCalendar dt a#calendar-previous,
.portletCalendar dt a#calendar-next {
  color: #7f99b2;
}
.ploneCalendar .todaynoevent,
.ploneCalendar .todayevent {
  border-color: #006699 !important;
}
.portletCalendar dt,
table.ploneCalendar,
.ploneCalendar .todayevent,
.ploneCalendar .event {
  /*background-color: rgb(229, 234, 239);*/
  border-color: rgb(229, 234, 239) !important;
}
.ploneCalendar .weekdays th {
  color: rgb(127, 153, 178);
  background-color: rgb(229, 234, 239);
  /*border-color: white !important;*/
}

/* Tabellen */

table { color: #000; }

table.plain th,
table.plain td,
table.listing,
table.listing .top,
table.listing th,
table.listing td {
  border-color: #6685a3;
}
table.listing th {
  background-color: #ccd6e0;
}
table.listing tr.odd td {
  background-color: #e5eaef;
}
table.listing tr.even td {
  background-color: #ffffff;
}

/* table of contents / Inhaltsverzeichnis*/

dl.toc {
  background-color: #e8e8e0;
}

/* Ergaenzung Lanyi */

#content tr.even,
#content td.even {
  background: #ffffff;
}
#content tr.odd,
#content td.odd {
  background-color: #ccd6e0;
}

/* feedfeeder-view */
.listingBar a:hover {
  color: #ffffff;
  background-color: #003366;
  border-color: #003366;
}

/* Ergaenzung LW */

/* frontpage styles */
.welcome {
  color: #003366;
}

#content .news-listing .title a,
#content .events-listing .title a {
  color: #006699 !important;
}

#content .news-listing .title a:hover,
#content .events-listing .title a:hover {
  color: #666633 !important;
}

#content .news-listing .title a:visited,
#content .events-listing .title a:visited {
  color: #4477aa !important;
}

#content .news-listing .info,
#content .events-listing .dates {
  color: #666666;
}


/* Mosaic */

.mosaic-plone\.app\.standardtiles\.contentlisting-tile .mosaic-tile-content h2.tileHeader { background-color: #ccd6e0; padding: .1em .6rem; margin: 0 -0.6rem; }
.mosaic-plone\.app\.standardtiles\.contentlisting-tile .mosaic-tile-content span.title a { color: #006699 !important; font-weight: bold; }
.mosaic-plone\.app\.standardtiles\.contentlisting-tile .mosaic-tile-content > div { margin: 0.5em 0 .5em 0 ;}
.mosaic-plone\.app\.standardtiles\.contentlisting-tile .mosaic-tile-content > div:not(:last-child) { border-bottom: 1px solid #B2C1D1; }