#portal-footer-wrapper {
  background-color: transparent;
  padding: 0;
}

#portal-footer {
  /*border-top: 1px solid $hu-footer-grey;
  padding-top: 1em;*/
  /* zl_temp plone6*/
  margin-top: -25px;

  padding-bottom: 1em;
  color: #696969;

  .documentActions {
    float: right;
  }

  #footer-left-container {
    @media (max-width: 767.98px) {
      padding: 0 1rem;
	  .share_link_menu {
		margin-left: 0;
	  }
      .share_link_menu li {
        margin: 0 4% 0 0;
      }
    }
  }
  #footer-right-container {
    margin-left: 0;

    .impressum,
    .photo_credits,
    .datenschutz,
    .barrierefreiheit {
      float: right;
      font-size: 80%;
    }
	.photo_credits,
    .datenschutz,
    .barrierefreiheit {
      margin-left: 2.5%;
    }
  }
  .subcolumns { padding: .6em 2em; }
  @media (min-width: 768px) {
    #CookieConsent {
	  width: 99.9em;
    }
    .subcolumns {
      padding: 1em 23%;
    }
  }

  #footer-analytics {
    padding-left: 0;
    padding-right: 0;
  }
}

#portal-anontools {
	float: left !important;
	font-size: 80%;
}

#portal-anontools ul {
    padding: 0 !important;
}

#portal-anontools li {
	margin: 0 0 1em 0 !important;
}

.documentByLine { margin-bottom: 0; }

div.footer p { display: none; }

@media only screen and (min-width: $plone-grid-float-breakpoint) {
	.newsletter-registration {
		padding-left: 20%;
		padding-right: 20%;
	}
}
.newsletter-registration {
    background-color: #CCD6E0;
    margin-bottom: 0;
    /*margin-top: 1em;*/
    padding-bottom: 0.5em;
    padding-top: 0.5em;
	
	h5 {
		color: rgb(0,102,153) !important;
		font-size: 100%;
		font-weight: bold;
		margin-left: 1.1em;
		margin-right: 0;
	}
}
.newsletter_registration_subtitle {
    margin-left: 2em;
	color: #000;
}
/*
#text5185435 {
    width: 66%;
    border: none;
    padding-left: 1em;
    font-size: 100%;
	display: inline;
}
*/
.nl_email {
    margin-left: 1em;
}
.nl_email input {
    height: 1.8em;
    width: 66%;
    border: none;
    padding-left: 1em;
    font-size: 100%;
    display: inline;
}
.cr_form {
    margin-top: 0.5em;
    padding-bottom: 0.5em;
}
.cr_button {
    background-color: #006699;
    border: none;
    color: #d5c7c7;
    font-size: 100%;
    padding-right: 13em;
    padding-top: 0.2em;
    padding-left: 0.8em;
    padding-bottom: 0.2em;
    width: 30%;
}
.cr_ipe_checkbox {
	/*vertical-align: baseline;*/
	margin: 0 .5em !important;
}
.dschutz {
    margin-left: 2em;
    margin-right: 1em;
    margin-top: 0.5em;
	color: #000;
}
#sirup {
    display: none;
    visibility: hidden;
}
/*
body.plone-toolbar-expanded #newsletter-registration {
	display: none;
}
*/
