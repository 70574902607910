.pat-structure {
  .popover {
    max-height: 300px;
    overflow-y: scroll;
  }
}

#history-list {
  .standalone,
  .destructive {
    white-space: normal;
  }
}

#plone-contentmenu-portletmanager {
  #portlet-manager-plone\.footerportlets,
  #portlet-manager-plone\.leftcolumn,
  #portlet-manager-plone\.rightcolumn {
    display: none;
  }
}

#viewlet-below-content .managePortletsLink {
  //display: none;
}

#edit-zone nav > ul > li.active ul {
    margin-top: 0 !important;
} 

.pattern-relateditems-item-title, .pattern-relateditems-result-title {
	font-size: 100%;
}


/*link and image box changes*/

.pattern-relateditems-result .pattern-relateditems-result-browse-wrapper .pattern-relateditems-result-browse {
    font-size: 1.5rem;
}

.linkModal .select2-container, .linkModal .pattern-relateditems-container {
    width: 100% !important;
}

div.linkModal fieldset .input-wrapper { 
	min-width: 20rem;
}

.upload-container .upload-area {
	width: 100% !important;
	max-width: none;
}

.break-line {
	width: 100%;
}
.break-line div.depth-select-box {
    display: inline-block;
}

/* tinymce start */

.mce-window {
	position: absolute;
}
.mce-btn-group:not(:first-child) {
	margin-left: 0;
}
.mce-content-body {
	padding: .5em;
	.embedded, .mce_hidden_border {
		border: 1px solid #003366;
	}
	.mce_hidden_description {
		display: block;
		color: #003366;
	}
	.mce_hidden_icon {
		display: inline;
		color: #003366;
	}
	.mce_hover:hover {
		cursor: pointer;
	}
	div.acc-heading::before {
		content: "►";
	}
	div.acc-heading {
		font-weight: bold;
		color: #006699;
	}
	div.acc-bellows {
		border: 1px solid #aaa;
	}
	.mce-textbox.mce-multiline {
		overflow-y: scroll;
	}
	.mce-content-body {
		padding: 0.7em;
	}
	img {
		border: 1px solid #003366;
	}
	img.image-inline, #content-core img.image-inline {
		margin: 0;
		height: auto;
	}
	img.image-right, #content-core img.image-right {
		margin: .1em 0 .5em 1em;
		height: auto;
	}
	img.image-left, #content-core img.image-left {
		margin: .1em 1em .5em 0;
		height: auto;
	}
	th, td {
		border: 1px solid #e5e5e5;
	}
	ul {
		line-height: 1.3em;
		list-style-image: url('++resource++humboldt.theme.images/bullet.gif');
		list-style-type: square;
		list-style-position: outside;
		padding: .5em 1em;
	}
	ol {
		padding: .5em 1em;
	}
}

div.mce-panel {
	img {
		border: 1px solid #003366;
	}
}

#tinymce img.image-inline, #content-core img.image-inline {
	margin: 0;
	height: auto;
}

#tinymce img.image-right, #content-core img.image-right {
	margin: .1em 0 .5em 1em;
	height: auto;
}

#tinymce img.image-left, #content-core img.image-left {
	margin: .1em 1em .5em 0;
	height: auto;
}
#tinymce figure img, #content-core figure img {
	margin: 0 !important;
	max-width: 100%;
	/*width: auto;*/
	height: auto;
}

#tinymce div.zwei, body > div.zwei {
	width: 49% !important;
}
#tinymce div.drei, body > div.drei {
	width: 28% !important;
}
#tinymce div.spalten, body > div.spalten {
	display: inline-block !important;
	padding-left: 2% !important;
	padding-right: 2% !important;
	margin: 0 !important;
}

.mce-menubar .mce-menubtn.mce-active {
	z-index: 65500 !important;
}

.mce_hidden_description, .mce_hidden_icon {
	display: none;
}

/* tinymce end */

#contentview-user_data-change-password {
	display: none;
}

img.thumb-tile {
	max-width: 64px;
}

/*----- customized plone 6 ----------*/

body#visual-portal-wrapper {
  overflow-y: auto !important;
  overflow-x: hidden !important;
}

body.plone-toolbar-left #edit-zone .nav-link {
  padding: .2rem 1rem .2rem .5rem;
}

.row {
  margin-top: .5rem;
}

.btn, .form-control, .form-select, .input-group-text {
  font-size: .9rem;
  padding: .1rem .3rem;
}

.dropdown-menu {
  font-size: .8rem;
  padding: .1rem .3rem;
}

.btn-link, .nav-link {
  color: #070;
}

.btn:hover {
  background-color: #ccc;
}

/* zl_temp removed on mail fron Katrin 8.1.25
.btn.disabled, .btn:disabled, fieldset:disabled .btn {
  color: #272 !important;
}
*/

.btn-primary {
  color: #fff;
  background-color: #007bb1;
  border-color: #00587e;
}
.btn-primary:hover {
  border-color: #00587e;
}

.btn-secondary {
  color: #4d4d4d;
  background-color: #e5e5e5;
  border-color: #b2b2b2;
}
.btn-secondary:hover {
  border-color: #b2b2b2;
}

.btn-outline-secondary {
  color: #050 !important;
}

.btn-check:checked + .btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check) + .btn:active {
  background-color: #ccc;
}

.page-link, .pat-structure table.dataTable tr td.actionmenu-container .btn {
  color: #337ab7;
}

.active > .page-link, .page-link.active {
  background-color: #337ab7;
}

#content ul {
  list-style: none outside none;
}