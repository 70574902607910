/* --------------------------------------------------------------

   highcontrast.css
   * Sets up some elements in higher contrast by adding using an added class to the body element

-------------------------------------------------------------- */

button.toggleContrast {
  background-color: transparent;
  border: none;
  width: 1.2em;
  margin: 0;
  padding: 0;
  vertical-align: top;
}

body.high-contrast {
	a, #portal-column-one ul.navTree a {
		text-decoration: underline !important;
	}
	#frontpageslider #sliderbox .slidebody h2 { color: #036 !important; }
	#frontpageslider #sliderbox .slidebody,
	.header-menu,
	#search-menu, #search-menu ul,
	#search-menu li, .block-title,
	.newsletter-registration,
	.spalten h3,
	table.listing th {
		background-color: #f1f3f5 !important;
	}
	.portletLanguageSelect { background-color: #003366; }
	button[type="submit"].cr_button { color: #fff; }
}