/*
#portal-logo img {
    filter: invert(60%) sepia(75%) saturate(503%) hue-rotate(62deg) brightness(93%) contrast(91%);
}
*/

.navTreeItem svg.plone-icon {
  display: none;
}

#edit-bar #edit-zone .toolbar-header {
  background-color: rgb(0, 88, 128);
}

.dropdown-menu .dropdown-header {
  color: #ccc!important;
}

h2 > nav {
  font-size: 75%;
}

article .vevent div.cal_info.clearfix {
  display: table-cell;
}