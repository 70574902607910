/*Mobile Menu */
.menu-mobile,
.menu-mobile-with-js,
.menu-mobile-button,
.menu-mobile-sub,
.menu-mobile-sub-button {
  display: none;
}

.menu-mobile-no-js {
  display: none;
}

@media only screen and (max-width: $plone-grid-float-breakpoint-max) {
  
  header.no-folder-image #portal-header #folder_image { display: none; }

  #portal-column-one aside.portlet.portletNavigationTree {
    display: none;
  }

  #portal-header {
    display: block;
    height: auto;

    .smallscreen.humboldt-logo {
      display: block;
    }
  }

  .header-menu {
    display: none;
  }

  #content-header {
    .portlet-portal-title,
    .hidden-xs {
      display: none;
    }
    #folder_image {
      top: auto;
      bottom: auto;
      left: auto;
      right: auto;
      display: block;
      position: relative;
      width: 100%;
      max-width: 360px;
      height: auto;
      margin-bottom: 1em;
      margin-left: auto;
      margin-right: auto;
    }
	.container {
	  padding: 0;
	}
  }

  /* .audience-menu, .navigation, .search-bar, .menu, #menu-bars, #Ebene2, #portal-column-two{
    display: none !important;
    }*/
  /*#portal-top{
        margin-top: 0 !important;
    }

    .humboldt-logo {
        margin-left: 20px !important;
    }*/
  div.menu-mobile-no-js {
    display: inline;
  }
  div.smallscreen.humboldt-logo {
    padding: .5em 0 !important;
  }
  div#portal-top {
    margin-top: 0 !important;
  }
  div.portal-title.frontpageslider.frame {
    display: none;
  }

  .navigation .portlet-portal-title {
    padding-top: 10px;
  }
  .portlet-portal-title {
	min-height: 11em;
  }
	
  #content-core .drei, #content-core .zwei {
	width: auto;
  }
  #content-core .spalten, #content-core .theme-block, #content-core .theme-block-with-image {
	padding: 0;
	display: block;
	right: auto;
  }

  #frontpageslider-bigslider-wrapper, #sidebar {
    display: none;
  }
}

@media only screen and (min-width: $plone-grid-float-breakpoint) {
  #portal-header > #frontpageslider {
    display: none;
  }
}

div.menu-mobile-with-js {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow: hidden;
  background-color: #003366;
  font-size: 21px;
  line-height: normal;
  display: block;
  box-sizing: content-box;
}
/*
line-height:2.1em;
*/
/*div.menu-mobile-with-js #language{
    padding-top:0px;
}*/

.menu-mobile-button {
  box-sizing: border-box;
  color: white;
  text-align: center;
  padding: 0px;
  float: left;
  display: inline;
  height: 45px;
  width: 2.2em;
  border: none;
  vertical-align: middle;
}
.menu-mobile-button.ersterButton {
  width: 2.7em;
  background-position: 70% 50% !important;
}
.menu-mobile-button:hover {
  cursor: pointer;
}
.menu-mobile-button:hover,
.menu-mobile-button:focus,
.menu-mobile-button-clicked {
  background-color: white !important;
  color: #003366 !important;
}

.menu-mobile-button:hover svg,
.menu-mobile-button:focus svg,
.menu-mobile-button-clicked svg {
  fill: #003366;
}

div.menu-mobile-sub {
  display: none;
  margin: 0;
  padding: 0;
  width: 100%;
  overflow: hidden;
  background-color: #cccc99;
  font-size: 12px;
  line-height: 4em;
  position: absolute;
  z-index: 10 !important;

  li {
    display: inline;
  }
}

div.menu-mobile-sub-button {
  color: #003366;
  width: 100%;
  height: 45px;
  display: block;
  padding: 0px 10px 0px 10px;
  border-style: solid;
  border-width: 1px 0px 0px 0px;
  border-color: white;
  cursor: pointer;
  font-size: 16px;
}

.navTreeItem-mobile a,
div.zielgruppe-sub a,
div.search-sub,
div.schnellzugang-sub a {
  color: #003366;
  width: 100%;
  height: 45px;
  display: block !important;
  padding: 0px 10px 0px 20px;
  border-style: solid;
  border-width: 1px 0px 0px 0px;
  border-color: white;
  position: relative;
  font-size: 16px;
  white-space: nowrap;
}
div.zielgruppe-sub a {
  color: white;
  background-color: #003366;
}
div.schnellzugang-sub a {
  background-color: #ccd6e0;
}
div.search-sub,
div.search-sub * {
  padding-left: 0px;
  position: absolute;
  z-index: 10 !important;
  width: 100%;
  display: inline;
}
div.search-sub form.searchform input.searchField,
div.search-sub form#searchform input.searchField {
  padding-left: 0.5em;
  box-sizing: border-box;
  width: 80%;
  height: 40px;
  font-size: 150%;
  border-top: none;
  border-bottom: 0.1em solid #003366;
  overflow: hidden;
}

a.search-input-button {
  position: absolute;
  margin-top: -1px;
  right: 10px;
  background-color: #003366;
  width: 20%;
  height: 39px;
  z-index: 10 !important;
  border: none;
  overflow: hidden;
  border-bottom: 0.1em solid #003366;
}

#menu-mobile-search {
  background-image: url('++resource++humboldt.theme.images/mobile/Lupe.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 30px;
}
button#menu-mobile-search {
  background-color: transparent;
}

#menu-mobile-search:hover,
#menu-mobile-search:focus,
#menu-mobile-search.menu-mobile-button-clicked {
  background-image: url('++resource++humboldt.theme.images/mobile/Lupe_black.svg');
  background-color: white;
  cursor: pointer;
}

div.search-sub form.searchform input[type='image'],
div.search-sub form#searchform input[type='image'] {
  display: none;
}

div.menu-mobile-sub-button:hover {
  background-color: white;
  color: #003366;
}
div.zielgruppe {
  background-color: #003366;
  color: white;
}
div.schnellzugang {
  background-color: #ccd6e0;
}

#Home:hover,
#Home:focus {
  background-image: url('++resource++humboldt.theme.images/mobile/Home_black.svg');
}

#Home {
  background-image: url('++resource++humboldt.theme.images/mobile/Home.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 1.01em;
}

#menu-mobile-search img {
  height: 100%;
  width: 30px;
}

#dropmenu {
  background-image: url('++resource++humboldt.theme.images/mobile/Menu.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 1em;
  background-color: transparent;
}

#dropmenu img {
  height: 100%;
  width: 30px;
}

#dropmenu:hover,
#dropmenu:focus {
  background-image: url('++resource++humboldt.theme.images/mobile/Menu_black.svg');
}
#dropmenu.menu-mobile-button-clicked {
  background-image: url('++resource++humboldt.theme.images/mobile/Cross.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 1em;
}

.navTreeItem-mobile li.cms-stoerungen{
    background-color: #ff9933;
	display: block;
}
